/* Notification controls
-------------------------------------------------- */
.notification-button.stuck {
    background: #333;
    box-shadow: 0px 3px 5px rgba(0,0,0,0.4);
    width: 50px;
    height: 50px;
    border-radius: 25px;
    position: fixed;
    left: 0;
    bottom: 0;
    margin: 0 0 20px 20px;
    z-index: 1040;
    cursor: pointer;
}

.notification-button.stuck::before {
    content: '';
    background: transparent;
    width: 42px;
    height: 42px;
    border-radius: 21px;
    position: absolute;
    top: 4px;
    left: 4px;
    border: 1px solid #FFF;
}

.notification-button.stuck .tnt-svg {
    font-size: 24px;
    text-align: center;
    color: #fff;
    width: 1em;
    margin: 13px;
}

.notification-button .popover {
    border-radius: 0;
    border-color: transparent;
    box-shadow: 0 1px 5px rgba(0,0,0,0.2);
    z-index: 1049;
}

.notification-button .popover-content {
    width: 200px;
}

.notification-button.stuck .badge-dot {
    width: 12px;
    height: 12px;
    right: 2px;
    top: 2px;
}

.notification-button.ring ul > li > a > svg,
.notification-button.ring > svg {
    animation: ring-bell 6s 1s ease-in-out infinite;
    transform-origin: 50% 1.25%;
}

@keyframes ring-bell {
    0% { transform: rotate(0); }
    1.5% { transform: rotate(38deg); }
    3% { transform: rotate(-36deg); }
    4.5% { transform: rotate(34deg); }
    6% { transform: rotate(-32deg); }
    7.5% { transform: rotate(30deg); }
    9% { transform: rotate(-28deg); }
    10.5% { transform: rotate(26deg); }
    12% { transform: rotate(-24deg); }
    13.5% { transform: rotate(22deg); }
    15% { transform: rotate(-20deg); }
    16.5% { transform: rotate(18deg); }
    18% { transform: rotate(-16deg); }
    19.5% { transform: rotate(14deg); }
    21% { transform: rotate(-12deg); }
    22.5% { transform: rotate(10deg); }
    24% { transform: rotate(-8deg); }
    25.5% { transform: rotate(6deg); }
    27% { transform: rotate(-4deg); }
    28.5% { transform: rotate(2deg); }
    30% { transform: rotate(-1deg); }
    31.5% { transform: rotate(1deg); }
    33% { transform: rotate(0); }
    100% { transform: rotate(0); }
}


/* Notification popup
-------------------------------------------------- */
.tnt-notification {
    position: fixed;
    opacity: 0;
    transition: all 0.4s cubic-bezier(0.2, 0.6, 0.4, 1);
    text-decoration: none;
    width: 360px;
    z-index: 1230;
    padding: 10px;
    background: #fff;
    box-shadow: 0px 2px 10px rgba(0,0,0,0.3);
    border: 1px solid rgba(10, 10, 10, 0.25);
    border-left: 4px solid #1B809E;
}

.tnt-notification.on {
    opacity: 1;
}

.tnt-notification-close {
    opacity: 0.4;
    padding: 0 5px;
    cursor: pointer;
}

.tnt-notification.right {
    right: 15px;
}

.tnt-notification.left {
    left: 15px;
}

.tnt-notification.top {
    top: -150px;
}

.tnt-notification.bottom {
    bottom: -150px;
}

@media only screen and (max-width:360px) {
    .tnt-notification.left, .tnt-notification.right {
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        max-width: fit-content;
    }
}

.tnt-notification .tnt-notification-dismiss {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
}

.tnt-notification img {
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
    width: 90px;
}

.tnt-notification h4 {
    margin: 0 0 10px 0;
}

.tnt-notification p {
    margin: 0;
}

.tnt-notification a {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

/* Notification panel
-------------------------------------------------- */
.notify-panel {
    visibility: hidden;
    transition: visibility 0s 0.3s;
}

.notify-panel.notify-panel-visible {
    visibility: visible;
    transition: visibility 0s 0s;
}

.notify-panel-from-right {
    right: 0;
}

.notify-panel-from-left {
    left: 0;
}

.notify-panel-container {
    width: 100%;
}

@media (min-width:360px) {
    .notify-panel-container {
        width: 360px;
    }
}

.notify-panel-container {
    position: fixed;
    height: 100%;
    top: 0;
    transition: transform 0.3s 0s cubic-bezier(0.4, 0.0, 0.2, 1);
    background-color: #333;
    color: #fff;
    z-index: 1130;
    overflow-y: auto;
}

.notify-panel-from-right .notify-panel-container {
    right: 0;
    transform: translate3d(100%, 0, 0);
}

.notify-panel-from-left .notify-panel-container {
    left: 0;
    transform: translate3d(-100%, 0, 0);
}

.notify-panel-visible .notify-panel-container {
    transform: translate3d(0, 0, 0);
    transition-delay: 0s;
}

.notify-panel-settings-promo {
    padding: 10px 20px;
}

.notify-panel-settings-promo p {
    font-size: 16px;
}

.notify-panel-container .tab-content {
    padding: 15px;
}

.notify-panel-content-no-items {
    font-size: 16px;
    color: rgba(255,255,255,0.5);
}

/* Notification panel header
-------------------------------------------------- */
.notify-panel-header {
    position: relative;
}

.notify-panel-header .notify-panel-nav {
    height: 50px;
    padding-right: 50px;
    background-color: #eee;
}

.notify-panel-header .notify-panel-nav a {
    width: 50%;
    float: left;
    line-height: 50px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 10px;
}

.notify-panel-header .notify-panel-close {
    font-size: 24px;
    color: #333;
    line-height: 1;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 300;
    padding: 12px 10px;
}

.notify-panel-header .notify-panel-nav ul {
    display: block;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.notify-panel-header .notify-panel-nav li.active a {
    background-color: #333;
    color: #fff;
}

/* Notification panel item
-------------------------------------------------- */
.notification-item {
    background-color:  #fff;
    padding: 10px;
    margin-bottom: 10px;
    color: #333;
    position: relative;
    opacity: 1;
    transition: opacity 0.3s cubic-bezier(0.2, 0.6, 0.4, 1);
}

.notification-item:hover {
    background-color: #f0f0f0;
}

.notification-item a {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.notification-item-card {
    display: flex;
}

.notification-item-image {
    margin-right: 10px;
    flex-basis: 50px;
    flex-shrink: 0;
    flex-grow: 0;
}

.notification-item-image img {
    width: auto;
    height: auto;
    display: block;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
}

.notification-item-header {
    font-weight: bold;
    padding-right: 15px;
}

.notification-item-dismiss {
    position: absolute;
    top: 4px;
    right: 4px;
    cursor:  pointer;
}

/* Notification topics
-------------------------------------------------- */
#notify-panel-settings h5 {
    text-transform: uppercase;
}

#notify-panel-settings .notify-panel-settings-error,
#notify-panel-settings .notify-panel-settings-success {
    transition: 0.5s;
}

#notify-panel-settings .topic-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#notify-panel-settings .switch-label {
    position: relative;
    z-index: 1;
    vertical-align: middle;
    display: inline-block;
    height: 24px;
    margin: 10px 0;
    padding: 0;
    padding-left: 36px;
}

#notify-panel-settings .switch-input {
    position: absolute;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
}

#notify-panel-settings .switch-track {
    background: rgba(255,255,255,0.5);
    position: absolute;
    left: 0;
    top: 5px;
    height: 14px;
    width: 36px;
    border-radius: 14px;
    cursor: pointer;
    transition: background cubic-bezier(0.4,0,0.2,1) 0.28s;
}

#notify-panel-settings .switch-thumb {
    background: #FAFAFA;
    position: absolute;
    left: 0;
    top: 2px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.15), 0 3px 1px -2px rgba(0,0,0,0.2), 0 1px 5px 0 rgba(0,0,0,0.12);
    transition-duration: .28s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-property: left, background;
}

#notify-panel-settings .switch-input:checked ~ .switch-track {
    background: rgba(96, 157, 210, 0.5);
}

#notify-panel-settings .switch-input:checked ~ .switch-thumb {
    background: #609dd2;
    left: 16px;
    box-shadow: 0 3px 4px 0 rgba(0,0,0,0.14), 0 3px 3px -2px rgba(0,0,0,0.2), 0 1px 8px 0 rgba(0,0,0,0.12);
}